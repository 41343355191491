import React from "react";
import { presentationTrips } from "../components/Trips/trips-data";
import MoreInformation from "../components/Trips/MoreInformation";
import { useTranslation } from "react-i18next";

const tripInformation = presentationTrips.find(
  (x) => x.id === "formentor-point-trip"
);

const FormentorPoint = () => {
  const { t } = useTranslation();
  return (
    <MoreInformation
      title="Formentor point"
      description="This boat trip will take us to Cap de Formentor, the most easterly point of Mallorca, starting the route in Puerto de Pollensa. On our route to Cap de Formentor we will cross the bay of Pollensa in the direction of Formentor, passing the secluded Cala Murta."
      tripInformation={tripInformation}
      timeTable={t(tripInformation.moreInformation.timetable)}
    />
  );
};

export default FormentorPoint;
